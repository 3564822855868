import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import api from '../utils/api';  
import Loader from '../components/Loader';
import Message from '../components/Message';

const OrderScreen = () => {
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState({ orderItems: [] });


  const [sdkReady, setSdkReady] = useState(false);
  const [paymentSessionId, setPaymentSessionId] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingPay, setLoadingPay] = useState(false);
  const [loadingDeliver, setLoadingDeliver] = useState(false);
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  
  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }

 


  const fetchOrderDetails = async () => {
    try {
      const { data } = await api.get(`/api/orders/${orderId}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setOrder(data || { orderItems: [] });
      setLoading(false);
     } catch (error) {
      console.error('Error fetching order:', error);
      setOrder({ orderItems: [] });
      setLoading(false);
      console.log(order);
    }
  };



const totalPrice = (order.orderItems || []).reduce(
  (acc, item) => acc + item.qty * item.price,
  0
);




    fetchOrderDetails();
  }, [userInfo, navigate, orderId]);

  

  const successPaymentHandler = async (paymentResult) => {
    try {
      setLoadingPay(true);
      const { data } = await api.put(
        `/api/orders/${orderId}/pay/`,
        paymentResult,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
  
      // Update order state instead of resetting it
      setOrder((prevOrder) => ({
        ...prevOrder,
        isPaid: true,
        paidAt: new Date().toISOString(),
      }));
  
      setLoadingPay(false);
      navigate(`/order/${orderId}`); // Redirect instead of full reload
    } catch (error) {
      setLoadingPay(false);
      setError("Payment failed");
    }
  };
  
  const displayCashfreePayment = async () => {
    try {
      const { data } = await api.post(
        `https://www.bookyourcelebration.com/api/orders/${orderId}/create-cashfree-payment/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`, // Ensure valid token
          },
        }
      );

      if (data.payment_session_id) {
        setPaymentSessionId(data.payment_session_id);
        openCashfreeCheckout(data.payment_session_id);  // ✅ Open checkout
      } else {
        console.error("Payment session ID not found:", data);
      }
    } catch (error) {
      console.error("Error creating Cashfree payment:", error.response?.data || error.message);
    }
    setLoading(false);
  };

  const openCashfreeCheckout = (sessionId) => {
    document.body.classList.add("cashfree-active");  // ✅ Apply special CSS for Cashfree
  
    const cashfree = window.Cashfree({
      mode: "production",  
    });
  
    cashfree.checkout({
      paymentSessionId: sessionId,
      redirectTarget: "_blank",  
    });
  
    // Remove class after payment process is complete
    setTimeout(() => {
      document.body.classList.remove("cashfree-active");
    }, 5000);
  };
  
  
  const deliverHandler = async () => {
    try {
      setLoadingDeliver(true);
      const { data } = await api.put(`/api/orders/${order._id}/deliver/`, {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setOrder(data);
      setLoadingDeliver(false);
      
    } catch (error) {
      setLoadingDeliver(false);
      setError('Booking Completion update failed');
    }
    };

  
  

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div style={{ marginTop: '10px', marginRight: '10px' }}>
      <h1>Order: {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Event Address</h2>
              <p>
                <strong>Name: </strong> {order.user && order.user.name}
              </p>
              <p>
                <strong>Email: </strong>
                <a href={`mailto:${order.user && order.user.email}`}>
                  {order.user && order.user.email}
                </a>
              </p>
              <p>
                <strong>Event Address: </strong>
                {order.shippingAddress && order.shippingAddress.address},{' '}
                {order.shippingAddress && order.shippingAddress.city}{' '}
                {order.shippingAddress && order.shippingAddress.postalCode},{' '}
                {order.shippingAddress && order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant="success">
                  Booked service is successfully completed on {order.deliveredAt}
                </Message>
              ) : (
                <Message variant="warning">Booked service not completed</Message>
              )}
            </ListGroup.Item>
  
            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong> {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant="success">Paid on {order.paidAt}</Message>
              ) : (
                <Message variant="warning">Not Paid</Message>
              )}
            </ListGroup.Item>
  
            <ListGroup.Item>
              <h2>Bookings</h2>
              {order.orderItems && order.orderItems.length > 0 ? (
  order.orderItems.map((item, index) => (
    <ListGroup.Item key={index}>
      <Row>
        <Col md={1}>
          <Image src={item.image} alt={item.name} fluid rounded />
        </Col>
        <Col>{item.name}</Col>
        <Col md={4}>
          {item.qty} x ₹{item.price} = ₹{(item.qty * item.price).toFixed(2)}
        </Col>
      </Row>
    </ListGroup.Item>
  ))
) : (
  <Message variant="info">No bookings found</Message>
)}

            </ListGroup.Item>
          </ListGroup>
        </Col>
  
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Booking Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Services Price:</Col>
                  <Col>
            ₹
            {(order.orderItems || [])
              .reduce((acc, item) => acc + item.qty * item.price, 0)
              .toFixed(2)}
          </Col>
                </Row>
              </ListGroup.Item>
  
              <ListGroup.Item>
                <Row>
                  <Col>Conveyance:</Col>
                  <Col>₹{order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
  
              <ListGroup.Item>
                <Row>
                  <Col>Tax:</Col>
                  <Col>₹{order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
  
              <ListGroup.Item>
                <Row>
                  <Col>Total:</Col>
                  <Col>₹{order.totalPrice}</Col>
  
 


                </Row>
              </ListGroup.Item>
            </ListGroup>
            {!order.isPaid && (
  <ListGroup.Item>
    <Button onClick={displayCashfreePayment}>
      Pay Now
    </Button>
  </ListGroup.Item>
)}

        
          </Card>
  
          {loadingDeliver && <Loader />}
          {userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered && (
            <ListGroup.Item>
              <Button
                type="button"
                className="btn btn-block"
                onClick={deliverHandler}
              >
                Mark As Completed
              </Button>
            </ListGroup.Item>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrderScreen;
