import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../utils/api';
import SearchBox from './SearchBox';
import '../index.css';

const Header = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(() => {
        // Initialize userInfo from localStorage
        const storedInfo = localStorage.getItem('userInfo');
        console.log('UserInfo in Header:', storedInfo);

        return storedInfo ? JSON.parse(storedInfo) : null;
    });

    const [notificationCount, setNotificationCount] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notificationsFetched, setNotificationsFetched] = useState(false);

    useEffect(() => {
        // Fetch notifications only for service-owner
        if (userInfo?.role === 'service-owner' && !notificationsFetched) {
            const fetchNotifications = async () => {
                try {
                    const { data } = await api.get('/api/orders/unread/', {
                        headers: { Authorization: `Bearer ${userInfo.token}` },
                    });
                    setNotificationCount(data.unread_count || 0);
                    setNotifications(data.orders || []);
                } catch (error) {
                    console.error('Failed to fetch notifications:', error);
                } finally {
                    setNotificationsFetched(true);
                }
            };

            fetchNotifications();
            const interval = setInterval(fetchNotifications, 60000); // Fetch notifications every 60 seconds
            return () => clearInterval(interval);
        }
    }, [userInfo, notificationsFetched]);
    const toggleNotifications = () => {
        setShowNotifications((prev) => !prev);
        markNotificationsAsRead();
        if (!showNotifications && notificationCount > 0) {
            markNotificationsAsRead();
        }
    };

    const markNotificationsAsRead = async () => {
        try {
            await api.post('/api/orders/mark-read/', {}, {
                headers: { Authorization: `Bearer ${userInfo.token}` },
            });

            setNotificationCount(0);
            setNotifications([]);
        } catch (error) {
            console.error('Failed to mark notifications as read:', error);
        }
    };

    useEffect(() => {
        // Listen to localStorage changes (e.g., after login or logout)
        const handleStorageChange = () => {
            const storedInfo = localStorage.getItem('userInfo');
            setUserInfo(storedInfo ? JSON.parse(storedInfo) : null);
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const logoutHandler = async () => {
        try {
            if (userInfo) {
                const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
                const wishlistItems = JSON.parse(localStorage.getItem('wishlists')) || [];

                await api.post('/api/products/cart/', { items: cartItems, userId: userInfo._id });
                await api.post('/api/products/wishlist/', { items: wishlistItems, user: userInfo._id }, {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                });
            }

            localStorage.removeItem('userInfo');
            setUserInfo(null);
            navigate('/login');
        } catch (error) {
            console.error('Error syncing data on logout:', error.message);
            localStorage.removeItem('userInfo');
            setUserInfo(null);
            navigate('/login');
        }
    };

    return (
        <header>
            <Navbar className="header-navbar" expand="lg">
                <Container>
                    <LinkContainer to="/">
                        <Navbar.Brand className="brand-logo">
                            <img
                                src="/images/android-chrome-192x192.png"
                                alt="Logo"
                                className="logo"
                                style={{ width: '50px', height: '50px', marginRight: '10px' }}
                            />
                            BookYourCelebration
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {userInfo?.role === 'customer' && (
                                <>
                                    <LinkContainer to="/">
                                        <Nav.Link>
                                            <i className="fas fa-home"></i> Home
                                        </Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/budget">
                                        <Nav.Link>
                                            <i className="fas fa-chart-pie"></i> Budget
                                        </Nav.Link>
                                    </LinkContainer>
                                    <SearchBox />
                                    <LinkContainer to="/wishlist">
                                        <Nav.Link>
                                            <i className="fas fa-heart"></i> Wishlist
                                        </Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/cart">
                                        <Nav.Link>
                                            <i className="fas fa-shopping-cart"></i> Cart
                                        </Nav.Link>
                                    </LinkContainer>
                                </>
                            )}
                            {userInfo?.role === 'service-owner' && (
                                <>
                                    <LinkContainer to="/">
                                        <Nav.Link>
                                            <i className="fas fa-home"></i> Home
                                        </Nav.Link>
                                    </LinkContainer>
                                    <NavDropdown title="Booking List" id="admin-nav-dropdown">
                                        <LinkContainer to="/orderlist/">
                                            <NavDropdown.Item>Booking List</NavDropdown.Item>
                                        </LinkContainer>
                                    </NavDropdown>
                                    <LinkContainer to="/wishlist">
                                        <Nav.Link>
                                            <i className="fas fa-heart"></i> Wishlist
                                        </Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/cart">
                                        <Nav.Link>
                                            <i className="fas fa-shopping-cart"></i> Cart
                                        </Nav.Link>
                                    </LinkContainer>
                                        <div>
                                    <div
                                        className="notification-container"
                                                onClick={toggleNotifications}
                                    >
                                        <i className="fas fa-bell"></i>
                                        {notificationCount > 0 && (
                                                    <span className="notification-badge">
                                                        {notificationCount}
                                                    </span>
                                                )}
                                            </div>
                                            {showNotifications && (
                                                <div className="notifications-dropdown">
                                                    {notifications.length > 0 ? (
                                                        notifications.map(
                                                            (notification, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="notification-item"
                                                                >
                                                                    <p>{notification.message}</p>
                                                                </div>
                                                            )
                                                        )
                                                    ) : (
                                                        <p>No new notifications</p>
                                                    )}
                                                </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {userInfo?.isAdmin && (
                                <NavDropdown title="Admin Menu" id="admin-nav-dropdown">
                                    <LinkContainer to="/admin/productlist">
                                        <NavDropdown.Item>Services List</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/admin/userlist">
                                        <NavDropdown.Item>User List</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Divider />
                                    <LinkContainer to="/admin/orderlist">
                                        <NavDropdown.Item>Booking List</NavDropdown.Item>
                                    </LinkContainer>
                                </NavDropdown>
                            )}
                        </Nav>
                        <Nav className="ms-auto">
                            {userInfo ? (
                                <NavDropdown title={<><i className="fas fa-user"></i> {userInfo.name}</>} id="username">
                                    <LinkContainer to="/profile">
                                        <NavDropdown.Item>Profile/Bookings</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <LinkContainer to="/login">
                                    <Nav.Link>Login</Nav.Link>
                                </LinkContainer>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
